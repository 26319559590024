import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Tax, TaxSerializer } from "./tax"
import { ProductPrice, ProductPriceSerializer } from "./product-price"
import { JsonObject } from "../_core/types"

export class CustomerProductPrice {
    id = 0
    createdAt: Date
    updatedAt: Date

    type: number
    regularity: number
    priceBuy: number
    priceBuyTax: Tax
    productPrice: ProductPrice
}

@Injectable({
    providedIn: "root",
})
export class CustomerProductPriceSerializer implements ApiResourceSerializer {
    constructor(private taxSerializer: TaxSerializer, private productPriceSerializer: ProductPriceSerializer) {}

    fromJson(json: JsonObject): CustomerProductPrice {
        const price = new CustomerProductPrice()
        price.id = json.id
        price.createdAt = new Date(json.created_at)
        price.updatedAt = new Date(json.updated_at)

        price.type = json.type
        price.regularity = json.regularity
        price.priceBuy = json.price_buy
        price.priceBuyTax = json.price_buy_tax !== undefined ? this.taxSerializer.fromJson(json.price_buy_tax) : null
        price.productPrice =
            json.product_price !== undefined && json.product_price !== null
                ? this.productPriceSerializer.fromJson(json.product_price)
                : null

        return price
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(customerProductPrice: CustomerProductPrice): any {
        return {
            price_buy: customerProductPrice.priceBuy,
            product_price:
                customerProductPrice.productPrice !== undefined && customerProductPrice.productPrice !== null
                    ? this.productPriceSerializer.toJson(customerProductPrice.productPrice)
                    : null,
        }
    }
}
