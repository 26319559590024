import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { ApiResource } from "./api-resource"
import { Currency, CurrencySerializer } from "./currency"
import { JsonObject } from "../_core/types"
import { PartnerMid } from "./partner-mid"

export class PartnerStorage implements ApiResource {
    id: number
    name: string
    hasAutoFreeCradles: boolean = false
    currency: Currency
    mids: PartnerMid[]
    hasPlatinumPricing: boolean = false
    hasStandardPricing: boolean = false
    customerLoyaltyPerTerminalAmount: number
    customerLoyaltyPerTerminalAmountLessVat: number
    partnerLoyaltyPerTerminalAmount: number
    partnerLoyaltyPerTerminalAmountLessVat: number
    isVatRegistered: boolean = false
    isTopUpEnabled: boolean = true
    cashbackCommissionPercentage: number = 100
    isSellerPlatinumEnabled: boolean = true
}

@Injectable({
    providedIn: "root",
})
export class PartnerStorageSerializer implements ApiResourceSerializer {
    constructor(private currencySerializer: CurrencySerializer) {}

    fromJson(json: JsonObject): PartnerStorage {
        const record = new PartnerStorage()
        record.id = json.id
        record.name = json.name
        record.hasAutoFreeCradles = json.has_auto_free_cradles
        record.hasPlatinumPricing = json.has_platinum_pricing
        record.hasStandardPricing = json.has_standard_pricing
        record.customerLoyaltyPerTerminalAmount = json.customer_loyalty_per_terminal_amount
        record.customerLoyaltyPerTerminalAmountLessVat = json.customer_loyalty_per_terminal_amount_less_vat
        record.partnerLoyaltyPerTerminalAmount = json.partner_loyalty_per_terminal_amount
        record.partnerLoyaltyPerTerminalAmountLessVat = json.partner_loyalty_per_terminal_amount_less_vat
        record.isVatRegistered = json.is_vat_registered
        record.cashbackCommissionPercentage =
            json.loyalty_commission !== undefined && json.loyalty_commission !== null ? json.loyalty_commission : 100
        record.isTopUpEnabled =
            json.is_top_up_enabled !== undefined && json.is_top_up_enabled !== null ? json.is_top_up_enabled : true

        record.currency =
            json.currency !== undefined && json.currency !== null
                ? this.currencySerializer.fromJson(json.currency)
                : null

        record.isSellerPlatinumEnabled = json.has_cashback
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: PartnerStorage): any {
        return {
            id: record.id,
            name: record.name,
            has_auto_free_cradles: record.hasAutoFreeCradles,
            has_platinum_pricing: record.hasPlatinumPricing,
            has_standard_pricing: record.hasStandardPricing,
            customer_loyalty_per_terminal_amount: record.customerLoyaltyPerTerminalAmount,
            customer_loyalty_per_terminal_amount_less_vat: record.customerLoyaltyPerTerminalAmountLessVat,
            partner_loyalty_per_terminal_amount: record.partnerLoyaltyPerTerminalAmount,
            partner_loyalty_per_terminal_amount_less_vat: record.partnerLoyaltyPerTerminalAmountLessVat,
            is_vat_registered: record.isVatRegistered,
            is_top_up_enabled: record.isTopUpEnabled,
            loyalty_commission: record.cashbackCommissionPercentage,
            has_cashback: record.isSellerPlatinumEnabled,

            currency:
                record.currency !== undefined && record.currency !== null
                    ? this.currencySerializer.toJson(record.currency)
                    : null,
        }
    }
}
